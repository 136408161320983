import React, {useState} from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import EpisodesList from '../components/episodesList'
import Youtube from '../images/youtube.png'
import Spotify from '../images/spotify.png'
import Itunes from '../images/itunes.png'
import Sabby from '../images/sabby-illustration.png'
import FeedbackModal from '../components/feedbackModal'
import FeaturedImgModal from '../components/featuredImgModal'

const Hero = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: 30px;
  padding-bottom: 120px;
  align-content: stretch;
  max-width: 1100px;
  margin: 0 auto;
  justify-content: space-between;
  padding-left: 3%;
  padding-right: 3%;

  .text {
    text-align: left;
    text-transform: capitalize;
  }
  .title {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 80px;
  }
  .title span {
    display: inline-block;
  }
  .subtitle {
    font-size: 24px;
    font-weight: lighter;
    margin: 16px 0;
  }
  .punctuation {
    color: #ffd664;
  }

  @media (max-width: 1440px) {
    .title {
      font-size: 80px;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    .text {
      max-width: 400px;
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding-top: 20%;
    padding-bottom: 80px;
    .title {
      font-size: 48px;
    }
    .subtitle {
      font-size: 16px;
    }
  }
`

const HeroImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;

  .icons {
    display: flex;
    justify-content: space-between;
    margin-right: -12px;
  }

  .icons img:hover {
    opacity: 0.5;
  }
  
  .icons img {
    object-fit: contain;
    width: 100%;
    padding-top: 30%;
  }

  .sabby {
    width: 75%;
    max-width: 600px;
    align-self: center;
    margin-top: -10%;
  }

  a .icon-middle {
    padding-top: 0px;
  }

  @media (max-width: 1440px) {
    max-width: 500px;
  }

  @media (max-width: 1024px) {
    max-width: 400px;
  }
  
  @media (max-width: 800px) {
    margin-top: 15%;
  }
`

const Featured = styled.div`
  display: flex;
  align-items: center;
  padding-top: 15px;
  flex-wrap: wrap-reverse;

  a {
    color: white;
  }

  a:hover {
    opacity: 0.8;
  }

  .image {
    background: none;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .featured {
    height: 80px;
    width: 60px;
    object-fit: contains;
    margin-right: 20px;
    box-shadow: black 2px 2px;
    border-radius: 2px;
  }

  .text {
    text-transform: none;
    font-size: 16px;
    line-height: 20px;
  }
`

const formatEpisodes = (item) => {
  return item.map(
    (item) => {
      return {
        title       : item.node.title,
        description : item.node.description,
        duration    : item.node.itunes.duration,
        number      : item.node.itunes.episode,
        image       : item.node.itunes.image.attrs.href,
        subtitle    : item.node.itunes.subtitle,
        summary     : item.node.itunes.summary,
        link        : item.node.link,
        pubDate     : new Date(item.node.pubDate),
        keywords    : item.node.itunes.keywords,
        fileId      : item.node.fileId,
        topics      : item.node.topics !== null
                        ? item.node.topics
                        : '',
        youtubeUrl  : item.node.youtubeUrl,
        spotifyUrl  : item.node.spotifyUrl,
        itunesUrl   : item.node.itunesUrl,
      }
    }
  )
}

const IndexPage = ({data}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFeaturedModalOpen, setIsFeaturedModalOpen] = useState(false);
  const [feedbackContext, setFeedbackContext] = useState(null);
  const episodes = formatEpisodes(data.allFeedPodcastEpisodes.edges);
  const categories = [
    {name: 'All',                  value: 'all'                  },
    {name: 'Cofounder',            value: 'cofounder'            },
    {name: 'Fundraising',          value: 'fundraising'          },
    {name: 'Challenge',            value: 'challenge'            },
    {name: 'Biases',               value: 'biases'               },
    {name: 'MVP',                  value: 'mvp'                  },
    {name: 'YC',                   value: 'yc'                   },
    {name: 'Idea',                 value: 'idea'                 },
    {name: 'Product',              value: 'product'              },
    {name: 'Startup Lessons',      value: 'startup-lessons'      },
    {name: 'Talking to Customers', value: 'talking-to-customers' },
    {name: 'Growth',               value: 'growth'               },
    {name: 'Pivot',                value: 'pivot'                },
   ]
  
   const openFeedbackModal = (episode) => {
     setFeedbackContext({
       episodeNumber: episode.number,
       episodeTitle: episode.title.replace(/.*: (.*) -.* - .*/, '$1')
     })
     setIsModalOpen(true)
   }

   const openFeaturedImgModal = () => {
     setIsFeaturedModalOpen(true);
   }

  return (
    <Layout>
      <Hero>
        <div className="text">
          <div className="title">
            <span className="punctuation">YC</span> <br/>
            INSIDER <br/>
            PERSPECTIVE
          </div>
          <div className="subtitle">
            Interviews with Y-Combinator Startup Founders
          </div>
          <Featured>
            <button className="image" onClick={() => openFeaturedImgModal()}>
              {/* <img src={FeaturedImg} alt="Featured" /> */}
              <Img className="featured" fluid={data.miniFeaturedImg.childImageSharp.fluid} />
                {/* JSON.stringify(data.miniFeaturedImg.childImageSharp.fluid) */}
            </button>
            <p className="text">
              Featured in <a href="https://disruptmagazine.com/21-podcasts-to-expand-your-mind-in-2021/" target="_blank" rel="noopener noreferrer">Disrupt</a> and <a href="https://markets.businessinsider.com/news/stocks/mindful-media-announces-21-podcasts-to-expand-your-mind-in-2021-1029607506" target="_blank" rel="noopener noreferrer">Business Insider</a> <br/>
              as Top 21 Podcasts to look out for in 2021
            </p>
            {/* <div className="images">
              <a href="https://disruptmagazine.com/21-podcasts-to-expand-your-mind-in-2021/" target="_blank" rel="noopener noreferrer">
                <img src={DisruptLogo} alt="Disrupt Magazine"/>
              </a>
              <a href="https://markets.businessinsider.com/news/stocks/mindful-media-announces-21-podcasts-to-expand-your-mind-in-2021-1029607506" target="_blank" rel="noopener noreferrer">
                <img src={BusinessInsiderLogo} alt="Business Insider" />
              </a>

            </div> */}
          </Featured>
        </div>
        <HeroImg>
          <div className="icons">
          <a href="https://www.youtube.com/channel/UC939WeUMJxe6aCFzwOWRjKA">
            <img src={Youtube} title="open youtube" alt="youtube logo" />
          </a>
          <a href="https://open.spotify.com/show/0Z2ja9B2DqmC4hapJyfnyA?si=Oml4tCBtRaeYRiYEKjHMGA">
            <img src={Spotify} title="open spotify" alt="spotify logo" className="icon-middle"/>
          </a>
          <a href="https://podcasts.apple.com/podcast/id1523944316?ct=podlink&mt=2&app=podcast&ls=1">
            <img src={Itunes}  title="open itunes" alt="itunes logo" />
          </a>

          </div>
          <img src={Sabby} alt="Sabby figure" className="sabby"/>
        </HeroImg>
      </Hero>
      
      <EpisodesList
        episodes={episodes}
        categories={categories}
        openFeedbackModal={openFeedbackModal}
      />
      <FeedbackModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        feedbackContext={feedbackContext}
        setFeedbackContext={setFeedbackContext}
      />
      <FeaturedImgModal
        isOpen={isFeaturedModalOpen}
        featuredImg={data.featuredImg.childImageSharp.fluid.src}
        setIsOpen={setIsFeaturedModalOpen}
      />

    </Layout>
  )
} 

export default IndexPage

export const query = graphql`
  {
    allFeedPodcastEpisodes
    {
      edges {
        node {
          title
          pubDate,
          link,
          description,
          itunes {
            summary
            episode
            subtitle
            duration
            keywords
            image {
              attrs {
                href
              }
            }
          }
          fileId
          topics
          youtubeUrl
          spotifyUrl
          itunesUrl
        }
      }
    },
    miniFeaturedImg: file(relativePath: { eq: "featured.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    featuredImg: file(relativePath: { eq: "featured.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`;