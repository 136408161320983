import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import moment from 'moment'
import Youtube from '../images/youtube.svg'
import Spotify from '../images/spotify.svg'
import Itunes from '../images/itunes.svg'
import FeedbackIcon from '../images/feedback-icon.png'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1100px;
  padding: 30px 0;
  margin-left: auto;
  margin-right: auto;

  .title {
    text-decoration: none;
    color: white;
  }

  .title:hover {
    text-decoration: underline;
  }

  .guest-image {
    -webkit-box-flex: 0;
    flex-grow: 0;
    border: 1px solid #494D5F;
    border-radius: 10px;
    max-width: 320px;
    box-sizing: content-box;
    width: 100%;
  }

  .info {
    flex-grow: 0;
    width: 100%;
    max-width: 600px;
    padding: 24px 8px;
    padding-top: 16px;
    margin: 0 auto;
  }

  .info-header {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
  }

  .guest {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
    color: white;
    text-decoration: none;
  }

  .guest:hover {
    text-decoration: underline;
  }

  .guest-info {
    font-size: 16px;
    color: #7D7F87;
    margin-bottom: 8px;
  }

  .date {
    padding: 5px 0;
  }

  .summary {
    font-size: 16px;
  }

  .links {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
  }
  .link-title {
    font-size: 14px;
    width: 100%;
    margin-bottom: 10px;
  }
  .link-pill {
    display: flex;
    font-size: 16px;
    border: 1px solid #494D5F;
    border-radius: 50px;
    padding: 12px 16px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    user-select: none;
    transition: .2s ease;
    text-decoration: none;
    color: white;
  }
  .link-pill img {
    height: 16px;
    margin-right: 8px;
  }
  .link-pill:hover {
    border-color: #666B82;
  }

  .topics {
    padding-top: 8px;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .topics span {
    margin-right: 8px;
    color: #7D7F87;
  }

  .summary, .topics {
    margin: 5px 0;
  }

  .feedback {
    font-size: 16px;
    margin: 10px 0;
    display: inline-block;
    border: 1px solid #FFD75B;
    border-radius: 50px;
    padding: 12px 16px;
    margin-right: 8px;
    cursor: pointer;
    user-select: none;
    transition: .2s ease;
    text-decoration: none;
    color: white;
    outline: none;
  }

  .feedback:hover {
    border-color: #FFD75Baa;
  }

  .feedback img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    padding: 10px 0;

    .guest-image, .info {
      max-width: 400px;
    }
  }
`

const Episode = ({episode, openFeedbackModal}) => {
  const guestName    = episode.title.replace(/.*: (.*) -.* - .*/, '$1').split(' ')[0].toLowerCase();
  const guestProject = episode.title.replace(/.*: .*- (.*) - .*/, '$1').split(' ')[0].toLowerCase();
  const episodeLink  = `/ycombinator/${guestProject}/${guestName}`
  return (
    <Container>
      <img className="guest-image" src={`https://ik.imagekit.io/entrepreneurgains/${episode.image.replace('https://ssl-static.libsyn.com/', '')}?tr=w-400,h-400` } alt={`episode.title`} />
      <div className="info">
        <div className="info-header">
          Episode #{episode.number}
          <div className="date">{ moment(episode.pubDate).format('MMM DD, YYYY') }</div>
        </div>
        <div style={{fontWeight: 'bold', fontSize: '24px'}}>
          {episode.title.replace(/.*: (.*) -.* - .*/, '$1')}
        </div>
        <div className="guest-info">
          Founder at {episode.title.replace(/.*: .*- (.*) - .*/, '$1')} {episode.title.replace(/.*(\(YC.*\))/, '$1')}
        </div>
        <p className="summary">
          {episode.summary}
        </p>
        {
          (episode.youtubeUrl||episode.spotifyUrl||episode.itunesUrl) &&
          <div className="links">
            <strong className="link-title">Watch on:</strong>
            {
              episode.youtubeUrl !== null && episode.youtubeUrl !== undefined &&
              <a rel="noopener noreferrer" target="_blank" href={episode.youtubeUrl} className="link-pill">
                <img src={Youtube} alt="Youtube Logo"/>Youtube
              </a>
            }
            {
              episode.spotifyUrl !== null && episode.spotifyUrl !== undefined &&
              <a rel="noopener noreferrer" target="_blank" href={episode.spotifyUrl} className="link-pill">
                <img src={Spotify} alt="Spotify Logo"/>Spotify
              </a>
            }
            {
              episode.itunesUrl !== null && episode.itunesUrl !== undefined &&
              <a rel="noopener noreferrer" target="_blank" href={episode.itunesUrl} className="link-pill">
                <img src={Itunes} alt="iTunes Logo"/>iTunes
              </a>
            }
          </div>
        }
        <div className="topics">
          {
            episode.topics !== '' && episode.topics.split(',')
            .map((topic, index) => <span key={index}>#{topic}</span>)
          }
        </div>
        <div className="feedback" role="button" tabIndex={0} onKeyDown={() => {}} onClick={() => openFeedbackModal(episode)}>
          <img src={FeedbackIcon} alt="Feedback icon" />Suggestions
        </div>
      </div>
    </Container>
  )
}

export default Episode
