import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import Modal from 'react-modal';
import axios from 'axios'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  input {
    height: 30px;
    outline: none;
    width: 285px;
  }

  p {
    opacity: 0.9;
  }

  button {
    margin: 10px auto;
    padding: 5px 10px;
    display: block;
  }

  textarea {
    outline: none;
  }
`

const modalStyles = {
  content: {
    backgroundColor: '#040303d9',
    color: 'white',
    margin: 'auto auto',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'

  },
  overlay: {
    zIndex: '10',
  }

}

const FeedbackModal = ({isOpen, setIsOpen, feedbackContext, setFeedbackContext}) => {

  //https://us-central1-entrepreneur-gains.cloudfunctions.net/feedback
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [about, setAbout] = useState('');
  const [feedback, setFeedback] = useState('');
  const formRef = useRef();

  useEffect(() => {
    if (feedbackContext !== null)  {
      setAbout(`Episode ${feedbackContext.episodeNumber} - ${feedbackContext.episodeTitle}`)
    }

  }, [feedbackContext])

  const closeModal = () => {
    setFeedbackContext(null);
    setIsOpen(false)
  }

  const checkFormValidity = () => {
    if (
      email.match(/.+@.*\..+/) &&
      feedback.length >= 10
    ) {
      return true
    } else {
      return false;
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if (checkFormValidity() === false) return

    axios({
      method: 'post',
      url: 'https://us-central1-entrepreneur-gains.cloudfunctions.net/feedback',
      // url: 'http://localhost:5001/entrepreneur-gains/us-central1/feedback',
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        feedback: {
          name: name,
          email: email,
          about: about,
          content: feedback
        }
      }
    }).then(
      (res) => {
        if (res.status === 200) {
          window.alert('Thanks for the feedback');
          setFeedbackContext(null);
          setIsOpen(false);
        }
        console.info(res)
      }, (error) => {
        window.alert('An error happened. Please try again later')
        console.error(error)
      }
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Feedback Modal"
      style={modalStyles}
      ariaHideApp={false}
    >
      <Container>

        <h1>
          Feedback
        </h1>

        <form ref={formRef}>
          <div>
            <p>
              Name (optional)
            </p>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
          </div>
          <div>
            <p>
              Email (optional)
            </p>
            <input
              value={email}
              type="email"
              required
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email@email.com"
            />
          </div>
          <div>
            <p>
              About (optional)
            </p>
            <input
              value={about}
              required
              minLength="20"
              onChange={(e) => setAbout(e.target.value)}
            />
          </div>
          <div>
            <p>
              Feedback
            </p>
            <textarea
              cols="33"
              rows="11"
              placeholder={`Is there any segment you really enjoyed about this episode?\n\nAnything you did not like?\n\nIs there any questions you would really like us to ask our future guests?\n\nPlease let us know.`}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
          </div>
          <button type="submit" onClick={(e) => onSubmit(e)} disabled={feedback.length < 10}>
            Send Feedback
          </button>
        </form>
      </Container>
    </Modal>

  )
}

export default FeedbackModal