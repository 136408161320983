import React, {useState} from 'react'
import styled from 'styled-components'
import CategoriesList from './categoriesList'
import Episode from './episode'

import LinesBG from '../images/lines-background.png'

const Container = styled.div`
  background: url(${LinesBG});
  background-repeat: no-repeat;
  background-position-y: center;
  display: flex;
  flex-direction: column;
  padding: 0 3%;

  label {
    max-width: 1100px;
    width: 100%;
    align-self: center;
    margin-bottom: 1em;
  }
  @media (max-width: 768px) {
    label {
      max-width: 400px;
    }
  }
`

const Episodes = styled.div`
`

const EpisodesList = ({episodes, categories, openFeedbackModal}) => {
  const [selectedCategory, setSelectedCategory] = useState(
    { name: 'All', value: 'all' }
  )

  return (
    <Container>
      <label htmlFor="categories">Choose a category:</label>
      <CategoriesList
        categories={categories}
        selectedCategory={selectedCategory}
        onChange={setSelectedCategory}
      />
      <Episodes>
        {
          episodes
          .filter(
            (episode) => selectedCategory.value === 'all'
              || episode.topics.includes(selectedCategory.value)
          )
          .map(
            (episode, index) => <Episode
              key={index}
              episode={episode}
              openFeedbackModal={openFeedbackModal}
            />
          )
        }
      </Episodes>
  
    </Container>
  )
}

export default EpisodesList
