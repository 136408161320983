import React from 'react'
import styled from 'styled-components'


const Categories = styled.select`
  display: flex;
  max-width: 1100px;
  width: 100%;
  background-color: #282a38;
  border: 1px solid #494D5F;
  height: 56px;
  border-radius: 5px;
  align-self: center;
  font-size: 1em;
  color: white;
  padding: 0 16px;
  margin-bottom: 40px;
  cursor: pointer;
  @media (max-width: 768px) {
    max-width: 400px;
  }
`

const CategoriesList = ({categories, selectedCategory, onChange}) => {

  const updateSelectedCategory = (value) => {
    onChange(categories.find((item) => item.value === value))
  }

  return (
    <Categories
      id="categoriesList"
      name="categories"
      value={selectedCategory.value}
      onChange={(e) => updateSelectedCategory(e.target.value)  } >
      {
        categories.map(
          (category, index) =>
          <option key={index} value={category.value}  >{category.name}</option>
        )
      }
    </Categories>
  )
}

export default CategoriesList
