import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal';

const Container = styled.div`
  max-width: 95%;
  max-height: 95%;
  margin: auto auto;
  background: white;

  img {
    object-fit: cover;
    max-width: 100%;
    display: block;
    max-height: 95%;
    margin: auto auto;
  }
`

const modalStyles = {
  content: {
    background: 'none',
    color: 'white',
    margin: 'auto auto',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '90%',
    maxHeight: '90%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'

  },
  overlay: {
    zIndex: '10',
  }

}

const FeaturedImgModal = ({isOpen, setIsOpen, featuredImg}) => {
  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Featured Img Modal"
      style={modalStyles}
      ariaHideApp={false}
    >
      <Container>
        <img src={featuredImg} alt="Featured" />
        

      </Container>
    </Modal>

  )
}

export default FeaturedImgModal